import { CSSProperties } from "react";
import ecoLabel from "../../lib/økologimærke.png";

interface EcoLabelProps {
  size?: 1 | 2 | 3 | 4 | 5 | 6;
  color?: string;
  style?: CSSProperties;
  onClick?(): void;
}

export function EcoLabel(props: EcoLabelProps) {
  return (
    <div
      style={{
        width: (props.size || 2) * 16,
        height: (props.size || 2) * 15,
        display: "inline-block",
        background: props.color || "red",
        WebkitMask: `url(${ecoLabel}) center/contain`,
        mask: `url(${ecoLabel}) center/contain`,
        ...props.style,
      }}
      onClick={props.onClick}
    />
  );
}
