import { FcOpenedFolder } from "react-icons/fc";
import { Folder as FolderI } from "@ckal-software/ckal-lib/dist/apps/madplanen";

interface FolderProps {
  folder: FolderI;
  onClick(): void;
}

export function FolderComponent(props: FolderProps) {
  return (
    <div onClick={props.onClick}>
      <div style={{ display: "flex", justifyContent: "center" }}>
        <FcOpenedFolder
          style={{ width: "80%", height: "80%", margin: "-16px -10px" }}
        />
      </div>
      <div
        style={{
          marginTop: "8px",
          color: "#636363",
          textAlign: "center",
          wordBreak: "break-word",
        }}
      >
        {props.folder.folderName}
      </div>
    </div>
  );
}
