import React from "react";
import { Input } from "antd";
import { useContext } from "react";
import { DataContext } from "../../lib/contexts";
import { InputProps } from "antd/lib/input";

interface MInputProps extends InputProps {
  innerref?: React.RefObject<HTMLInputElement | null>;
}

export function MInput(props: MInputProps) {
  const { setIsInputting } = useContext(DataContext);

  return (
    <Input
      id={`${Math.random()}`}
      {...props}
      // @ts-expect-error i don't know
      ref={props.innerref}
      onFocus={(e) => {
        setIsInputting(true);
        if (props.onFocus) {
          props.onFocus(e);
        }
      }}
      onBlur={(e) => {
        setIsInputting(false);
        if (props.onBlur) {
          props.onBlur(e);
        }
      }}
    />
  );
}
