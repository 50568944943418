import { CSSProperties, useRef } from "react";

interface CircleButtonProps {
  text?: string | number;
  hidden?: boolean;
  onClick?(): void;
  onLongPress?(): void;
  style?: CSSProperties;
}

export function CircleButton(props: CircleButtonProps) {
  const timer = useRef<NodeJS.Timeout>();
  const noClick = useRef(false);

  return (
    <div
      style={{
        height: 32,
        width: 32,
        color: "686666",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        visibility: props.hidden ? "hidden" : undefined,
        fontSize: 14,
        borderRadius: "50%",
        border: "1px solid #b3b3b3",
        ...props.style,
      }}
      className="unselectable"
      onClick={(e) => {
        e.stopPropagation();
        if (props.onClick && !noClick.current) {
          props.onClick();
        }
      }}
      onPointerDown={() => {
        timer.current = setTimeout(() => {
          if (props.onLongPress) {
            props.onLongPress();
          }
          noClick.current = true;
        }, 400);
      }}
      onPointerUp={() => {
        if (timer.current) {
          clearTimeout(timer.current);
        }
        setTimeout(() => {
          noClick.current = false;
        }, 200);
      }}
    >
      {props.text}
    </div>
  );
}
