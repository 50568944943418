import { Checkbox } from "antd";
import { useContext } from "react";
import { DataContext } from "../../lib/contexts";
import { categoryToIcon } from "../../lib/definitions";
import { ShoppingListItemIdAndAmount } from "../../lib/models";
import { CircleButton } from "../elements/circleButton";
import { EcoLabel } from "../elements/ecoLabel";
import { categoryToColor, ItemCategoryWithBought } from "../../lib/lib";

interface CategorySectionProps {
  category: ItemCategoryWithBought;
  items: ShoppingListItemIdAndAmount[];
  onItemClick(item: ShoppingListItemIdAndAmount): void;
  onAmountClick(item: ShoppingListItemIdAndAmount, amount?: number): void;
  onClear(): void;
}

export function CategorySection(props: CategorySectionProps) {
  const { itemsMap } = useContext(DataContext);

  return (
    <div>
      <div
        style={{
          margin: "16px 0 8px 29px",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <div style={{ display: "flex" }}>
          <div
            style={{
              fontSize: "20px",
              display: "flex",
              alignItems: "center",
              marginRight: "14px",
            }}
          >
            {categoryToIcon[props.category]}
          </div>
          <div
            style={{
              color: categoryToColor[props.category],
              fontWeight: "bold",
              fontSize: "14px",
            }}
          >
            {props.category.toUpperCase()}
          </div>
        </div>
        {props.category === "Købte" && (
          <div onClick={props.onClear} style={{ marginRight: "32px" }}>
            Ryd
          </div>
        )}
      </div>
      {props.items.map((item) => (
        <div
          onClick={() => props.onItemClick(item)}
          key={item._id}
          style={{
            background: "white",
            padding: "8px 16px 8px 32px",
            fontSize: "18px",
            borderBottom: "1px solid #dde1e5",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              color: props.category === "Købte" ? "#a7a7a7" : undefined,
              textDecoration:
                props.category === "Købte" ? "line-through" : undefined,
            }}
          >
            <Checkbox
              style={{ marginRight: "16px" }}
              checked={props.category === "Købte"}
            />
            <div>
              {item.isOther ? item._id : itemsMap[item._id]?.name || ""}
            </div>
          </div>
          <div style={{ display: "flex", alignItems: "center" }}>
            {(item.isOther === 2 ||
              (!item.isOther && itemsMap[item._id]?.isOrganic)) && (
              <EcoLabel
                style={{ transform: "scale(0.9)", marginRight: "16px" }}
                color={props.category === "Købte" ? "lightgray" : undefined}
              />
            )}
            <CircleButton
              text={item.amount}
              onClick={() => props.onAmountClick(item)}
              onLongPress={() => props.onAmountClick(item, 1 - item.amount)}
              hidden={props.category === "Købte"}
            />
          </div>
        </div>
      ))}
    </div>
  );
}
