import React from "react";

interface SettingsContentContainerProps {
  title?: string;
  children?: React.ReactNode;
}

export function SettingsContentContainer(props: SettingsContentContainerProps) {
  return (
    <div>
      {props.title && <h2 style={{ marginBottom: "16px" }}>{props.title}</h2>}
      {props.children}
    </div>
  );
}
