import { useContext, useMemo, useState } from "react";
import { AllUsersContext, GroupContext } from "../../lib/contexts";
import { SettingsContentContainer } from "./settingsContentContainer";
import { Button } from "antd";
import { MModal } from "../elements/mmodal";
import { PinInput } from "../general/pinInput";
import { MInput } from "../elements/mInputs";
import { BsPeopleFill } from "react-icons/bs";
import { Avatar } from "../general/Avatar";
import { callEndpoint } from "../../lib/functions";
import {
  CreateGroupEndpoint,
  JoinGroupEndpoint,
  LeaveGroupEndpoint,
  UpdateGroupEndpoint,
} from "@ckal-software/ckal-lib/dist/apps/madplanen";

export function GroupContent() {
  const [groupId, setGroupId] = useState(-1);
  const [newGroupName, setNewGroupName] = useState("");
  const [isCreatingGroup, setIsCreatingGroup] = useState(false);
  const [isJoiningGroup, setIsJoiningGroup] = useState(false);
  const [isLeavingGroup, setIsLeavingGroup] = useState(false);
  const [isChangingGroupName, setIsChangingGroupName] = useState(false);
  const [showNewGroupPopup, setShowNewGroupPopup] = useState(false);
  const [showJoinGroupPopup, setShowJoinGroupPopup] = useState(false);
  const [showChangeGroupNamePopup, setShowChangeGroupNamePopup] =
    useState(false);

  const { group, fetchMyGroup } = useContext(GroupContext);
  const allUsers = useContext(AllUsersContext);

  const members = useMemo(
    () =>
      (group?.members || []).map((m) => allUsers.find((u) => u.email === m)),
    [allUsers, group]
  );

  return (
    <div>
      <SettingsContentContainer title={group?.name}>
        {group ? (
          <>
            <div style={{ marginTop: "24px", fontSize: "18px" }}>
              Gruppe-ID: <code>{group?.shortId}</code>
            </div>
            <div style={{ marginTop: "24px", display: "grid", rowGap: 8 }}>
              <h3>Medlemmer</h3>
              {members.map((m, i) => (
                <div
                  key={i}
                  style={{
                    display: "flex",
                    columnGap: 8,
                    alignItems: "center",
                  }}
                >
                  <Avatar
                    email={m?.email || ""}
                    firstname={m?.firstname || ""}
                    lastname={m?.lastname || ""}
                    size={32}
                  />
                  <div style={{ fontSize: "16px" }}>{m?.fullName}</div>
                </div>
              ))}
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                marginTop: "24px",
              }}
            >
              <Button
                size="large"
                style={{ marginRight: "8px" }}
                onClick={() => setShowChangeGroupNamePopup(true)}
              >
                Skift gruppenavn
              </Button>
              <Button
                size="large"
                danger
                type="primary"
                loading={isLeavingGroup}
                onClick={async () => {
                  if (
                    window.confirm("Er du sikker på at du vil forlade gruppen?")
                  ) {
                    setIsLeavingGroup(true);

                    const [err] = await callEndpoint(
                      LeaveGroupEndpoint,
                      undefined
                    );

                    if (err) {
                      alert(`Forlad gruppe: Der skete en fejl: ${err.error}`);
                    } else {
                      await fetchMyGroup();
                    }
                    setIsLeavingGroup(false);
                  }
                }}
              >
                Forlad gruppe
              </Button>
            </div>
          </>
        ) : (
          <div>
            <div style={{ display: "flex", justifyContent: "space-around" }}>
              <Button
                size="large"
                style={{ marginLeft: "8px" }}
                type="primary"
                loading={isCreatingGroup}
                onClick={() => setShowNewGroupPopup(true)}
              >
                Lav ny gruppe
              </Button>
              <Button
                size="large"
                style={{ marginLeft: "8px" }}
                onClick={() => setShowJoinGroupPopup(true)}
              >
                Deltag i gruppe
              </Button>
            </div>
          </div>
        )}
      </SettingsContentContainer>
      <MModal
        visible={showChangeGroupNamePopup}
        onClose={() => setShowChangeGroupNamePopup(false)}
        title="Skift gruppenavn"
        content={
          <div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                marginBottom: "24px",
              }}
            >
              <BsPeopleFill style={{ fontSize: "120px" }} />
            </div>
            <MInput
              placeholder="Nyt gruppenavn"
              size="large"
              value={newGroupName}
              autoFocus
              onChange={(e) => setNewGroupName(e.target.value)}
            />
          </div>
        }
        footer={
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <Button
              onClick={() => setShowChangeGroupNamePopup(false)}
              size="large"
              style={{ marginRight: "8px" }}
            >
              Luk
            </Button>
            <Button
              type="primary"
              onClick={async () => {
                const [err] = await callEndpoint(
                  UpdateGroupEndpoint,
                  { name: newGroupName },
                  { setWorking: setIsChangingGroupName }
                );

                if (err) {
                  alert("Ændring af gruppenavn: Der skete en fejl");
                  return;
                }

                await fetchMyGroup();
                setShowChangeGroupNamePopup(false);
              }}
              size="large"
              loading={isChangingGroupName}
              disabled={!newGroupName}
            >
              Skift navn
            </Button>
          </div>
        }
      />
      <MModal
        visible={showJoinGroupPopup}
        onClose={() => setShowJoinGroupPopup(false)}
        title="Deltag i gruppe"
        content={
          <div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                marginBottom: "24px",
              }}
            >
              <BsPeopleFill style={{ fontSize: "120px" }} />
            </div>
            <div style={{ marginBottom: "8px" }}>Indtast Gruppe-ID:</div>
            <PinInput onPinChange={setGroupId} />
          </div>
        }
        footer={
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <Button
              onClick={() => setShowJoinGroupPopup(false)}
              size="large"
              style={{ marginRight: "8px" }}
            >
              Luk
            </Button>
            <Button
              onClick={async () => {
                setIsJoiningGroup(true);

                const [err, , { response }] = await callEndpoint(
                  JoinGroupEndpoint,
                  { shortId: groupId }
                );

                if (err) {
                  if (response?.status === 404) {
                    alert(`Gruppe med Gruppe-ID ${groupId} eksisterer ikke`);
                  } else {
                    alert(`Deltag i gruppe: Der skete en fejl`);
                  }
                  setIsJoiningGroup(false);
                  return;
                }

                await fetchMyGroup();
                setShowJoinGroupPopup(false);
              }}
              loading={isJoiningGroup}
              disabled={groupId.toString().length !== 6}
              type="primary"
              size="large"
            >
              Deltag
            </Button>
          </div>
        }
      />
      <MModal
        visible={showNewGroupPopup}
        onClose={() => setShowNewGroupPopup(false)}
        title="Ny gruppe"
        content={
          <div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                marginBottom: "24px",
              }}
            >
              <BsPeopleFill style={{ fontSize: "120px" }} />
            </div>
            <MInput
              placeholder="Gruppenavn"
              size="large"
              value={newGroupName}
              onChange={(e) => setNewGroupName(e.target.value)}
            />
          </div>
        }
        footer={
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <Button
              size="large"
              onClick={() => setShowNewGroupPopup(false)}
              style={{ marginRight: "16px" }}
            >
              Luk
            </Button>
            <Button
              size="large"
              type="primary"
              disabled={!newGroupName}
              loading={isCreatingGroup}
              onClick={async () => {
                setIsCreatingGroup(true);

                const [err] = await callEndpoint(CreateGroupEndpoint, {
                  name: newGroupName,
                });

                if (err) {
                  setIsCreatingGroup(false);
                  alert("Lav ny gruppe: Der skete en fejl");
                  return;
                }

                await fetchMyGroup();
                setShowNewGroupPopup(false);
                setIsCreatingGroup(false);
              }}
            >
              Lav gruppe
            </Button>
          </div>
        }
      />
    </div>
  );
}
