import { useEffect, useState } from "react";
import { VERY_LIGHT_GRAY } from "../../lib/style_definitions";
import { CategoryItem } from "./categoryItem";
import * as localForage from "localforage";
import { LF_CATEGORY_ORDER } from "../../lib/lib";
import { ItemCategory } from "@ckal-software/ckal-lib/dist/apps/madplanen";

export function CategoryOrderContent() {
  const [orderedCategories, setOrderedCategories] = useState<ItemCategory[]>(
    []
  );

  useEffect(() => {
    (async () => {
      const maybeOrderedCategories = (await localForage.getItem(
        LF_CATEGORY_ORDER
      )) as ItemCategory[];
      if (
        maybeOrderedCategories &&
        maybeOrderedCategories.length === Object.values(ItemCategory).length
      ) {
        setOrderedCategories(maybeOrderedCategories);
      } else {
        setOrderedCategories(Object.values(ItemCategory));
      }
    })();
  }, []);

  function changeOrder(index: number, wasUp: boolean) {
    const changedElement = orderedCategories[index];
    const newOrderedCategories = [...orderedCategories];
    newOrderedCategories[index] =
      newOrderedCategories[index + (wasUp ? -1 : 1)];
    newOrderedCategories[index + (wasUp ? -1 : 1)] = changedElement;

    localForage.setItem(LF_CATEGORY_ORDER, newOrderedCategories);
    setOrderedCategories(newOrderedCategories);
  }

  return (
    <div
      style={{
        height: "280px",
        overflow: "auto",
        padding: "8px 16px",
        border: "1px solid " + VERY_LIGHT_GRAY,
        borderRadius: "4px",
        background: "white",
      }}
    >
      <div
        style={{
          display: "grid",
          gridTemplateColumns: "min-content auto min-content min-content",
          columnGap: "8px",
          rowGap: "8px",
          alignItems: "center",
        }}
      >
        {orderedCategories.map((category, i) => (
          <CategoryItem
            key={category}
            category={category}
            onChange={(wasUp) => changeOrder(i, wasUp)}
            upDisabled={i === 0}
            downDisabled={i === orderedCategories.length - 1}
          />
        ))}
      </div>
    </div>
  );
}
