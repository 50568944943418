import { useCallback, useEffect, useMemo, useState } from "react";
import { CatalogueView } from "./views/catalogView";
import { WeekView } from "./views/weekView";
import { ShoppingView } from "./views/shoppingView";
import { NavigationBar } from "./general/navigationBar";
import {
  AllUsersContext,
  DataContext,
  GroupContext,
  UserInfoContext,
} from "../lib/contexts";
import { SettingsView } from "./views/settingsView";
import { Action, LF_LAST_PATH } from "../lib/lib";
import { ItemWithPopularity, ShoppingListItem } from "../lib/models";
import { Navigate, Route, Routes } from "react-router-dom";
import { callEndpoint } from "../lib/functions";
import { UpsertDish } from "./views/upsertDish";
import * as localForage from "localforage";
import { useLocation } from "react-router-dom";
import { fetchStatistics } from "../lib/statisticsHandler";
import { CurrentActionBar } from "./general/currentActionBar";
import "react-toastify/dist/ReactToastify.css";
import {
  DataEndpoint,
  Dish,
  Folder,
  FoldersEndpoint,
  Group,
  GroupEndpoint,
  ItemsEndpoint,
  ShoppingList,
  ShoppingListEndpoint,
  VisibleDishesEndpoint,
  WeekPlan,
  WeekPlanEndpoint,
} from "@ckal-software/ckal-lib/dist/apps/madplanen";
import { UserInfo } from "@ckal-software/ckal-lib";
import { FolderNamedAll } from "../lib/definitions";

interface MainProps {
  userInfo: UserInfo;
  onLogout(): void;
}

export function Main(props: MainProps) {
  const [group, setGroup] = useState<Group>();
  const [items, setItems] = useState<ItemWithPopularity[]>([]);
  const [isFetchingItems, setIsFetchingItems] = useState(false);
  const [hasFetchedGroup, setHasFetchedGroup] = useState(false);
  const [weekPlan, setWeekPlan] = useState<WeekPlan>();
  const [isFetchingWeekPlan, setIsFetchingWeekPlan] = useState(false);
  const [dishes, setDishes] = useState<Dish[]>([]);
  const [isFetchingDishes, setIsFetchingDishes] = useState(false);
  const [shoppingList, setShoppingList] = useState<ShoppingList>();
  const [isFetchingShoppingList, setIsFetchingShoppingList] = useState(true);
  const [allUsers, setAllUsers] = useState<UserInfo[]>([]);
  const [isInputting, setIsInputting] = useState(false);
  const [folders, setFolders] = useState<Folder[]>([]);
  const [isFetchingFolders, setIsFetchingFolders] = useState(false);
  const [currentAction, setCurrentAction] = useState<Action>();
  const [selectedFolder, setSelectedFolder] = useState<Folder>();
  const [locked, setLocked] = useState(false);

  const location = useLocation();

  const fetchMyGroup = useCallback(async () => {
    const [err, group, { response, fetchError }] = await callEndpoint(
      GroupEndpoint,
      undefined
    );

    setHasFetchedGroup(true);

    if (err) {
      if (fetchError) {
        return;
      }
      if (response?.status === 404) {
        setGroup(undefined);
      } else {
        alert("Hentning af gruppe: Der skete en fejl");
      }
      return;
    }

    setGroup(group);
  }, []);

  useEffect(() => {
    (async () => {
      const [err, data, { fetchError }] = await callEndpoint(
        DataEndpoint,
        undefined
      );

      if (err) {
        if (!fetchError) {
          alert(`Hentning af data: ${err.error}`);
        }
        return;
      }

      setDishes(data.dishes);
      setFolders([FolderNamedAll].concat(...data.folders));
      setItems(data.items);
      setWeekPlan(data.weekPlan);
      setShoppingList(data.shoppingList);
      setAllUsers(data.users);
    })();
  }, []);

  useEffect(() => {
    fetchMyGroup();
  }, [fetchMyGroup]);

  useEffect(() => {
    localForage.setItem(LF_LAST_PATH, location.pathname);
  }, [location]);

  const refreshShoppingList = useCallback(async () => {
    const [err, resultShoppingList, { fetchError }] = await callEndpoint(
      ShoppingListEndpoint,
      undefined,
      { setWorking: setIsFetchingShoppingList }
    );

    if (err) {
      if (!fetchError) {
        alert(`Hentning af indkøbliste: Der skete en fejl: ${err.error}`);
      }
      return;
    }

    setShoppingList(resultShoppingList);
  }, []);

  const itemsMap = useMemo(() => {
    const m: { [id in string]: ShoppingListItem } = {};
    items.forEach((item) => (m[item._id] = item));
    return m;
  }, [items]);

  const refreshFolders = useCallback(async () => {
    setIsFetchingFolders(true);

    const [err, resultFolders, { response, fetchError }] = await callEndpoint(
      FoldersEndpoint,
      undefined,
      { setWorking: setIsFetchingFolders }
    );

    if (err) {
      if (response?.status !== 404 && !fetchError) {
        alert("Hentning af mapper: Der skete en fejl");
      }

      return;
    }

    setFolders([FolderNamedAll].concat(...resultFolders));
  }, []);

  const refreshWeekPlan = useCallback(async () => {
    const [err, resultWeekPlan, { fetchError }] = await callEndpoint(
      WeekPlanEndpoint,
      undefined,
      { setWorking: setIsFetchingWeekPlan }
    );

    if (err) {
      if (!fetchError) {
        alert(`Hentning af madplan: Der skete en fejl: ${err.error}`);
      }
      return;
    }

    setWeekPlan(resultWeekPlan);
  }, [setWeekPlan]);

  const refresh = useCallback(() => {
    refreshShoppingList();
    refreshWeekPlan();
    refreshFolders();
  }, [refreshShoppingList, refreshWeekPlan, refreshFolders]);

  useEffect(() => {
    refresh();

    function refreshOnVisibilityChange() {
      if (document.visibilityState === "visible") {
        refresh();
      }
    }

    document.addEventListener("visibilitychange", refreshOnVisibilityChange);

    return () => {
      document.removeEventListener(
        "visibilitychange",
        refreshOnVisibilityChange
      );
    };
  }, [refresh]);

  async function refreshDishes() {
    const [err, resultDishes, { response, fetchError }] = await callEndpoint(
      VisibleDishesEndpoint,
      undefined,
      { setWorking: setIsFetchingDishes }
    );

    if (err) {
      if (response?.status !== 404 && !fetchError) {
        alert(`Hentning af opskrifter: Der skete en fejl: ${err.error}`);
      }
      return;
    }

    setDishes(resultDishes.sort((a, b) => a.name.localeCompare(b.name)));
  }

  async function refreshItems(noLoading?: boolean) {
    if (!noLoading) {
      setIsFetchingItems(true);
    }

    const [err, resultItems, { fetchError }] = await callEndpoint(
      ItemsEndpoint,
      undefined
    );
    setIsFetchingItems(false);

    if (err) {
      if (!fetchError) {
        alert(`Hentning af varer: Der skete en fejl: ${err.error}`);
      }
      return;
    }

    const statistics = await fetchStatistics();
    setItems(
      resultItems.map((i) => ({
        ...i,
        popularity: statistics[i._id] ? statistics[i._id].popularity : 0,
      }))
    );
  }

  return (
    <GroupContext.Provider value={{ group, fetchMyGroup, hasFetchedGroup }}>
      <DataContext.Provider
        value={{
          shoppingList,
          isFetchingShoppingList,
          setShoppingList,
          dishes,
          refreshDishes,
          isFetchingDishes,
          weekPlan,
          setWeekPlan,
          refreshWeekPlan,
          isFetchingWeekPlan,
          items,
          setItems,
          itemsMap,
          refreshItems,
          isFetchingItems,
          refreshShoppingList,
          setIsInputting,
          folders,
          setFolders,
          isFetchingFolders,
          refreshFolders,
          currentAction,
          setCurrentAction,
          selectedFolder,
          setSelectedFolder,
          locked,
          setLocked,
        }}
      >
        <UserInfoContext.Provider value={props.userInfo}>
          <AllUsersContext.Provider value={allUsers}>
            <Routes>
              <Route path="/shopping-list" element={<ShoppingView />} />
              <Route path="/week-plan" element={<WeekView />} />
              <Route path="/catalogue" element={<CatalogueView />} />
              <Route
                path="/profile"
                element={<SettingsView onLogout={props.onLogout} />}
              />
              <Route path="/upsert-dish/:id?" element={<UpsertDish />} />
              <Route path="/" element={<Navigate to="/shopping-list" />} />
            </Routes>
            {currentAction && (
              <CurrentActionBar
                currentAction={currentAction}
                onCancel={() => setCurrentAction(undefined)}
              />
            )}
            <NavigationBar isInputting={isInputting} />
          </AllUsersContext.Provider>
        </UserInfoContext.Provider>
      </DataContext.Provider>
    </GroupContext.Provider>
  );
}
