import { CSSProperties } from "react";
import { BLUE, GRAY, RED } from "./style_definitions";
import { YearWeekDay } from "./models";
import {
  Dish,
  ItemCategory,
} from "@ckal-software/ckal-lib/dist/apps/madplanen";
import { CKALCredentialsHandler } from "@ckal-software/ckal-lib";

export const DOMAIN = "ckal.dk";

export const LF_LAST_PATH = "last_path";
export const LF_STATISTICS = "stastistics";
export const LF_LAST_FOLDER = "last_folder";

export const AWS_CLIENT_ID = "6nki0f24aj9hrvluekbmkea631";

export const CSE_API_KEY = "AIzaSyDhYhQywkSpEs49siq4_MeWdAk37uIz_UU";
export const CSE_ID = "7579fb426896f48f4";

export type Weekday =
  | "Mandag"
  | "Tirsdag"
  | "Onsdag"
  | "Torsdag"
  | "Fredag"
  | "Lørdag"
  | "Søndag";

export type ItemCategoryWithBought = ItemCategory | "Købte";

export const categoryToColor: Record<ItemCategoryWithBought, string> = {
  Baby: "#d7b693",
  Bolig: GRAY,
  "Brød & Bager": "#e2a75d",
  Drikkevarer: "#04afe4",
  Elektronik: "black",
  "Frugt & Grønt": "#8bc34a",
  Frost: "#91c2d2",
  Kolonial: "brown",
  "Kød & Fisk": "#c30000",
  "Hus & Have": "green",
  Medicin: GRAY,
  Mejeri: "#04afe4",
  "Personlig pleje": "purple",
  "På køl": "#04afe4",
  Rengøring: GRAY,
  Snacks: RED,
  Tekstil: GRAY,
  Andet: GRAY,
  Købte: GRAY,
};

export const categoryIconStyles: CSSProperties = {
  marginBottom: "1px",
};

export const SEARCH_ITEM_LIST_HEIGHT = 300;

export const dishTypeToColor: { [k in string]: string } = {
  Morgen: "#6cbf9d",
  Middag: "#ec7237",
  Forret: "#d4cb64",
  Hovedret: RED,
  Dessert: BLUE,
};

export const LF_GROUP_ID = "groupId";
export const LF_CATEGORY_ORDER = "categoryOrder";

export enum ActionName {
  SelectDishForFolder = "SELECT_DISH_FOR_FOLDER",
  SelectFolderForDish = "SELECT_FOLDER_FOR_DISH",
  SelectDishForDay = "SELECT_DISH_FOR_DAY",
  SelectDayForDish = "SELECT_DAY_FOR_DISH",
}

export type Action =
  | {
      action: ActionName.SelectDishForFolder;
      data: { _id: string; folderName: string; dishIds: string[] };
    }
  | { action: ActionName.SelectDishForDay; data: YearWeekDay }
  | { action: ActionName.SelectDayForDish; data: { _id: string; name: string } }
  | {
      action: ActionName.SelectFolderForDish;
      data: Dish;
    };

export type Unpacked<T> = T extends (infer U)[] ? U : T;

export const PLACEHOLDER_URL =
  "https://usercontent.one/wp/www.vocaleurope.eu/wp-content/uploads/no-image.jpg?media=1642546813";

export const CredentialsHandler = new CKALCredentialsHandler("madplanen");
