import * as localForage from "localforage";
import { Statistic, Statistics } from "./models";
import { LF_STATISTICS } from "./lib";

export async function addItemToStats(itemId: string) {
  const statistics = await fetchStatistics();

  if (!statistics[itemId]) {
    statistics[itemId] = { _id: itemId, timesAdded: 0, popularity: 0 };
  }

  statistics[itemId].timesAdded++;

  let maxTimesAdded = 0;

  for (const item in statistics) {
    maxTimesAdded = Math.max(maxTimesAdded, statistics[item].timesAdded);
  }

  const newStatistics: Statistics = {};

  for (const itemName in statistics) {
    newStatistics[itemName] = {
      ...statistics[itemName],
      popularity: popularityMapper(
        statistics[itemName].timesAdded / maxTimesAdded
      ),
    };
  }
  await localForage.setItem(LF_STATISTICS, newStatistics);
}

export async function fetchStatistics(): Promise<Statistics> {
  const statistics = (await localForage.getItem(LF_STATISTICS)) as Statistics;
  return statistics || {};
}

export async function clearStatistic(itemName: string) {
  const statistics = await fetchStatistics();
  delete statistics[itemName];
  await localForage.setItem(LF_STATISTICS, statistics);
}

export async function clearStatistics() {
  await localForage.setItem(LF_STATISTICS, {});
}

export async function getStatistics(): Promise<Statistic[]> {
  const statistics = await fetchStatistics();

  const result: Statistic[] = [];

  for (const item in statistics) {
    result.push(statistics[item]);
  }

  result.sort((a, b) => b.popularity - a.popularity);

  return result;
}

function popularityMapper(popularity: number) {
  if (popularity < 0.05) return 0;
  if (popularity < 0.1) return 0.21;
  if (popularity < 0.2) return 0.41;
  if (popularity < 0.4) return 0.61;
  if (popularity < 0.6) return 0.81;
  return 1;
}
