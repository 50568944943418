import { useContext, useEffect, useState } from "react";
import { DeleteOutlined, PlusOutlined } from "@ant-design/icons";
import { DataContext } from "../../lib/contexts";
import { getDateFromYearWeekDay, intToWeekday } from "../../lib/functions";
import { YearWeekDay } from "../../lib/models";
import { BLUE } from "../../lib/style_definitions";
import LinesEllipsis from "react-lines-ellipsis";
import { Label } from "../elements/label";
import { Dish } from "@ckal-software/ckal-lib/dist/apps/madplanen";
import { dishTypeToColor, PLACEHOLDER_URL } from "../../lib/lib";

interface DayContentProps {
  id?: string;
  dishIds: string[];
  yearWeekDay: YearWeekDay;
  day: number;
  highlight?: boolean;
  isOutdated?: boolean;
  isCurrentDay?: boolean;
  onAddDish(): void;
  onDishClick(dish: Dish, index: number): void;
  onClear(): void;
}

export function DayContent(props: DayContentProps) {
  const [dayDishes, setDayDishes] = useState<Dish[]>();

  const { dishes } = useContext(DataContext);

  useEffect(() => {
    setDayDishes(
      props.dishIds
        .map((d) => dishes.find((dish) => dish._id === d))
        .filter((d) => !!d) as Dish[]
    );
  }, [props.dishIds, dishes]);

  return (
    <div id={props.id}>
      <div
        style={{
          marginBottom: "8px",
          fontWeight: "bold",
          color: "#636363",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <div style={{ display: "flex" }}>
          <div style={{ width: "84px" }}>
            {intToWeekday(props.day).toUpperCase()}
          </div>
          <div
            style={{
              fontWeight: props.isCurrentDay ? "bold" : "normal",
              color: props.isCurrentDay ? BLUE : "#989898",
              fontSize: "14px",
              marginTop: "1px",
            }}
          >
            {getDateFromYearWeekDay(props.yearWeekDay, props.day)}
          </div>
        </div>
        {props.dishIds.length !== 0 && !props.isOutdated && (
          <div style={{ display: "flex", alignItems: "center" }}>
            <PlusOutlined
              style={{ fontSize: "20px" }}
              onClick={props.onAddDish}
            />
            <DeleteOutlined
              style={{ fontSize: "20px", margin: "0 16px" }}
              onClick={props.onClear}
            />
          </div>
        )}
      </div>
      <div style={{ display: "grid", rowGap: "12px", gridAutoRows: "90px" }}>
        {dayDishes?.map((dish, i) => (
          <div
            key={dish._id + i}
            style={{
              display: "flex",
              justifyContent: "center",
              opacity: props.isOutdated ? 0.7 : undefined,
            }}
            onClick={() => props.onDishClick(dish, i)}
          >
            <div
              style={{
                width: "90%",
              }}
            >
              <div
                style={{
                  width: "100%",
                  height: "100%",
                  background: "white",
                  boxShadow:
                    "0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)",
                  display: "flex",
                }}
              >
                <div
                  style={{
                    backgroundImage: `url(${dish.imageUrl}), url(${PLACEHOLDER_URL})`,
                    width: "40%",
                    height: "100%",
                    backgroundSize: "cover",
                    backgroundPosition: "center center",
                  }}
                />

                <div
                  style={{
                    width: "60%",
                    padding: "8px 8px 4px 8px",
                    fontWeight: "bold",
                    fontSize: "12px",
                    lineHeight: "16px",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      marginBottom: "6px",
                    }}
                  >
                    <Label
                      text={dish.type}
                      color={dishTypeToColor[dish.type]}
                    />
                    <div style={{ fontWeight: "normal", marginLeft: "8px" }}>
                      {dish.time} min
                    </div>
                  </div>
                  <LinesEllipsis
                    text={dish.name.toUpperCase()}
                    maxLine="3"
                    ellipsis="..."
                    trimRight
                    basedOn="letters"
                  />
                </div>
              </div>
            </div>
          </div>
        ))}
        {dayDishes?.length === 0 && (
          <div
            style={{ width: "100%", display: "flex", justifyContent: "center" }}
            onClick={() => !props.isOutdated && props.onAddDish()}
          >
            <div
              style={{
                border: props.isOutdated
                  ? "2px dashed gray"
                  : props.highlight
                  ? `2px dashed ${BLUE}`
                  : "2px dashed black",
                background: props.isOutdated ? undefined : "white",
                width: "90%",
                display: "flex",
                height: "100%",
                justifyContent: "center",
                alignItems: "center",
                fontSize: "14px",
                color: props.isOutdated
                  ? "gray"
                  : props.highlight
                  ? BLUE
                  : undefined,
              }}
            >
              {props.isOutdated ? (
                "Ingen planer for dagen"
              ) : (
                <div style={{ alignItems: "center", display: "flex" }}>
                  <PlusOutlined
                    style={{
                      marginRight: "12px",
                      marginBottom: "2px",
                      fontSize: "20px",
                    }}
                  />{" "}
                  Tilføj ret til dagen
                </div>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
