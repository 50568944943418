import { Spin } from "antd";
import { HTMLProps } from "react";
import { FaChevronLeft } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { BLUE, GRAY_BACKGROUND } from "../../lib/style_definitions";

interface ViewProps extends HTMLProps<"div"> {
  isLoading?: boolean;
  scrollable?: boolean;
  header?: React.ReactNode;
  goBack?: boolean;
  scrollableContainerId?: string;
}

export function View(props: ViewProps) {
  const navigate = useNavigate();

  return (
    <div
      id={props.scrollableContainerId}
      style={{
        height: "100%",
        overflowY: props.scrollable ? "auto" : "hidden",
        overflowX: "hidden",
        background: GRAY_BACKGROUND,
      }}
    >
      {props.isLoading ? (
        <div
          style={{
            marginTop: "70%",
          }}
        >
          <Spin size="large" />
        </div>
      ) : (
        <>
          {props.header && (
            <div
              style={{
                padding: "24px",
                fontSize: "26px",
                fontWeight: "bold",
                background: BLUE,
                color: "white",
                marginBottom: "24px",
                width: "calc(100% - 48px)",
                textAlign: "center",
                position: "relative",
              }}
            >
              {props.goBack && (
                <FaChevronLeft
                  style={{
                    position: "absolute",
                    left: "24px",
                    top: "50%",
                    transform: "translate(0,-50%)",
                  }}
                  onClick={() => navigate(-1)}
                />
              )}
              {typeof props.header === "string"
                ? props.header.toString().toUpperCase()
                : props.header}
            </div>
          )}
          {props.children}
        </>
      )}
    </div>
  );
}
