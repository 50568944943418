import { LoadingOutlined, ShoppingCartOutlined } from "@ant-design/icons";
import { Button } from "antd";
import { useContext, useEffect, useMemo, useState } from "react";
import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { DataContext, GroupContext } from "../../lib/contexts";
import {
  callEndpoint,
  getDateFromYearWeekDay,
  getStringFromYearWeekDay,
  getYearWeekDayRelative,
} from "../../lib/functions";
import { YearWeekDay } from "../../lib/models";
import { BLUE } from "../../lib/style_definitions";
import { DishPopup } from "../dishes/dishPopup";
import { ViewMessage } from "../general/viewMessage";
import { AddToShoppingListPopup } from "../shopping/addToShoppingListPopup";
import { DayContent } from "../weekOverview/dayContent";
import { WeekNumberSelector } from "../weekOverview/weekNumberSelector";
import { View } from "./view";
import * as localForage from "localforage";
import {
  Dish,
  UpdateWeekPlanEndpoint,
  WeekPlan,
} from "@ckal-software/ckal-lib/dist/apps/madplanen";
import { ActionName } from "../../lib/lib";

const dayNumbers = [0, 1, 2, 3, 4, 5, 6];

export function WeekView() {
  const [yearWeekDay, setYearWeekDay] = useState<YearWeekDay>(
    getYearWeekDayRelative(0)
  );
  const [selectedDish, setSelectedDish] = useState<Dish>();
  const [indexOfSelectedDish, setIndexOfSelectedDish] = useState(-1);
  const [selectedDay, setSelectedDay] = useState(-1);
  const [dishIds, setDishIds] = useState<string[]>([]);
  const [isUpdatingWeekPlan, setIsUpdatingWeekPlan] = useState(false);

  const navigate = useNavigate();

  const { group, hasFetchedGroup } = useContext(GroupContext);
  const {
    weekPlan,
    refreshWeekPlan,
    isFetchingWeekPlan,
    dishes,
    folders,
    currentAction,
    setCurrentAction,
    setWeekPlan,
  } = useContext(DataContext);

  useEffect(() => {
    const weekNumber =
      new URLSearchParams(window.location.search).get("week") || "";
    if (weekNumber) {
      setYearWeekDay(
        getYearWeekDayRelative(
          Number.parseInt(weekNumber) - getYearWeekDayRelative(0).week
        )
      );
    }
    localForage
      .getItem("weekViewScrollPos", (_err, val) =>
        document
          .getElementById("week-view-container")
          ?.scrollTo(0, val as number)
      )
      .then(() => localForage.setItem("weekViewScrollPos", 0));
  }, []);

  useEffect(() => {
    setSelectedDish(
      dishes.find(
        (d) =>
          d._id ===
          new URLSearchParams(window.location.search).get("selected-dish")
      )
    );
  }, [dishes]);

  useEffect(() => {
    if (
      JSON.stringify(yearWeekDay) ===
        JSON.stringify(getYearWeekDayRelative(0)) &&
      !(new URLSearchParams(window.location.search).get("week") || "")
    ) {
      setTimeout(
        () =>
          document.getElementById("week-view-container")?.scroll({
            top: yearWeekDay.day * (117 + 36) - 150,
            behavior: "smooth",
          }),
        50
      );
    } else {
      document.getElementById("week-view-container")?.scrollTo(0, 0);
    }
  }, [yearWeekDay]);

  const isOutdated = useCallback(
    (dayNumber: number) =>
      getYearWeekDayRelative(0).week === yearWeekDay.week
        ? getYearWeekDayRelative(0).day > dayNumber
        : getYearWeekDayRelative(0).week > yearWeekDay.week,
    [yearWeekDay]
  );

  const weeksDishIds = useMemo(() => {
    const ids: string[] = [];
    for (let i = 0; i < 7; i++) {
      if (!isOutdated(i)) {
        ids.push(
          ...(weekPlan?.weekPlans[getStringFromYearWeekDay(yearWeekDay, i)] ||
            [])
        );
      }
    }
    return ids;
  }, [weekPlan, yearWeekDay, isOutdated]);

  async function addDishToDay(day: number, id: string) {
    const ywdString = getStringFromYearWeekDay(yearWeekDay, day);
    const newDishes = (
      weekPlan?.weekPlans[
        getStringFromYearWeekDay({ ...yearWeekDay, day: day })
      ] || []
    ).concat([id]);

    // instant update
    const newWeekPlan: WeekPlan = JSON.parse(JSON.stringify(weekPlan));
    newWeekPlan.weekPlans[ywdString] = newDishes;
    setWeekPlan(newWeekPlan);

    const [err] = await callEndpoint(UpdateWeekPlanEndpoint, {
      yearWeekDay: ywdString,
      dishIds: newDishes,
    });

    if (err) {
      alert("Opdatering af madplan: Der skete en fejl");
      return;
    }

    refreshWeekPlan();
  }

  return (
    <View>
      {!group ? (
        <ViewMessage
          message="Du er ikke en del af nogen gruppe endnu."
          otherMessage="Gå i indstillinger for at lave eller deltage i en gruppe..."
          loading={!hasFetchedGroup}
        />
      ) : (
        <>
          <div
            style={{
              background: "white",
              padding: "10px 20px",
              width: "calc(100% - 40px)",
              boxShadow:
                "0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)",
              display: "flex",
              alignItems: "center",
            }}
          >
            <WeekNumberSelector
              yearWeekDay={yearWeekDay}
              setYearWeekDay={setYearWeekDay}
            />
            {(isFetchingWeekPlan || isUpdatingWeekPlan) && (
              <div style={{ position: "absolute", right: "20px" }}>
                <LoadingOutlined
                  style={{ color: BLUE, fontSize: "24px", marginTop: "4px" }}
                />
              </div>
            )}
          </div>
          <div
            style={{
              overflow: "auto",
              height: "calc(100% - 16px)",
              width: "calc(100% - 48px)",
              padding: "16px 24px 0 24px",
            }}
            id="week-view-container"
          >
            {dishes
              .filter((d) => weeksDishIds.includes(d._id))
              .reduce((prev, curr) => prev + curr.ingredients.length, 0) >
              0 && (
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  marginBottom: "16px",
                }}
              >
                <Button
                  icon={<ShoppingCartOutlined />}
                  size="large"
                  onClick={() => setDishIds(weeksDishIds)}
                >
                  Overfør
                </Button>
              </div>
            )}
            <div style={{ rowGap: "36px", display: "grid" }}>
              {dayNumbers.map((n) => (
                <DayContent
                  id={`day-${n}`}
                  key={n}
                  dishIds={
                    weekPlan?.weekPlans[
                      getStringFromYearWeekDay(yearWeekDay, n)
                    ] || []
                  }
                  yearWeekDay={yearWeekDay}
                  day={n}
                  isOutdated={isOutdated(n)}
                  isCurrentDay={
                    JSON.stringify(getYearWeekDayRelative(0)) ===
                    JSON.stringify({ ...yearWeekDay, day: n })
                  }
                  onDishClick={(dish, index) => {
                    if (currentAction?.action === "SELECT_DAY_FOR_DISH") {
                      addDishToDay(n, currentAction.data._id);
                      setCurrentAction(undefined);
                    } else {
                      setSelectedDish(dish);
                      setSelectedDay(n);
                      setIndexOfSelectedDish(index);
                    }
                  }}
                  onAddDish={() => {
                    if (currentAction?.action === "SELECT_DAY_FOR_DISH") {
                      addDishToDay(n, currentAction.data._id);
                      setCurrentAction(undefined);
                    } else {
                      localForage.setItem(
                        "weekViewScrollPos",
                        document.getElementById("week-view-container")
                          ?.scrollTop
                      );
                      setCurrentAction({
                        action: ActionName.SelectDishForDay,
                        data: { ...yearWeekDay, day: n },
                      });
                      navigate("/catalogue");
                    }
                  }}
                  onClear={async () => {
                    const ywdString = getStringFromYearWeekDay(yearWeekDay, n);

                    // instant update
                    const newWeekPlan: WeekPlan = JSON.parse(
                      JSON.stringify(weekPlan)
                    );
                    newWeekPlan.weekPlans[ywdString] = [];
                    setWeekPlan(newWeekPlan);

                    const [err] = await callEndpoint(
                      UpdateWeekPlanEndpoint,
                      { yearWeekDay: ywdString, dishIds: [] },
                      { setWorking: setIsUpdatingWeekPlan }
                    );

                    if (err) {
                      alert("Opdatering af madplan: Der skete en fejl");
                      return;
                    }

                    refreshWeekPlan();
                  }}
                  highlight={
                    currentAction?.action === ActionName.SelectDayForDish
                  }
                />
              ))}
            </div>
            <div style={{ marginBottom: "192px" }} />
          </div>
        </>
      )}
      {selectedDish && (
        <DishPopup
          dish={selectedDish}
          visible={!!selectedDish}
          onClose={() => setSelectedDish(undefined)}
          onAddToWeekPlan={() => {
            setSelectedDish(undefined);
            setCurrentAction({
              action: ActionName.SelectDayForDish,
              data: selectedDish,
            });
          }}
          onRemoveFromDay={
            isOutdated(selectedDay)
              ? undefined
              : {
                  dateString: getDateFromYearWeekDay(yearWeekDay, selectedDay),
                  onClick: async () => {
                    setSelectedDish(undefined);
                    setIndexOfSelectedDish(-1);
                    setSelectedDay(-1);

                    const ywdString = getStringFromYearWeekDay(
                      yearWeekDay,
                      selectedDay
                    );
                    const newDishes = [
                      ...weekPlan!.weekPlans[ywdString],
                    ].filter((_dish, index) => index !== indexOfSelectedDish);

                    // instant update a
                    const newWeekPlan: WeekPlan = JSON.parse(
                      JSON.stringify(weekPlan)
                    );
                    newWeekPlan.weekPlans[ywdString] = newDishes;
                    setWeekPlan(newWeekPlan);

                    const [err] = await callEndpoint(
                      UpdateWeekPlanEndpoint,
                      { yearWeekDay: ywdString, dishIds: newDishes },
                      { setWorking: setIsUpdatingWeekPlan }
                    );

                    if (err) {
                      alert("Opdatering af madplan: Der skete en fejl");
                      return;
                    }

                    refreshWeekPlan();
                  },
                }
          }
          onEditSaveRedirect={`/week-plan?selected-dish=${selectedDish._id}`}
          folders={folders}
        />
      )}
      <AddToShoppingListPopup
        visible={dishIds.length > 0}
        onClose={() => setDishIds([])}
        dishIds={dishIds}
      />
    </View>
  );
}
