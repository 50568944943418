import { CSSProperties } from "react";
import { CSSTransition } from "react-transition-group";

interface MModalProps {
  visible: boolean;
  content: React.ReactNode;
  onClose(): void;
  footer?: React.ReactNode;
  title?: string;
  style?: CSSProperties;
  className?: string;
}

export function MModal(props: MModalProps) {
  const { visible, onClose } = props;

  return (
    <>
      {visible && (
        <div className="mask">
          <div
            className={"mmodal-cover " + props.className}
            style={{
              ...props.style,
            }}
          >
            <div className={"mmodal"}>
              {props.title && (
                <div
                  style={{
                    marginBottom: "16px",
                    fontWeight: "bold",
                    color: "#636363",
                  }}
                >
                  {props.title.toUpperCase()}
                </div>
              )}
              {props.content}
              {props.footer && (
                <div style={{ marginTop: "48px" }}>{props.footer}</div>
              )}
            </div>
            <div style={{ height: "112px" }} onClick={onClose} />
          </div>
          <CSSTransition timeout={200} classNames="fade-item">
            <div className="mask" onClick={onClose} />
          </CSSTransition>
        </div>
      )}
    </>
  );
}
