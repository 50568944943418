import { useContext, useEffect, useMemo, useRef, useState } from "react";
import {
  EditOutlined,
  LoadingOutlined,
  PlusOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import { View } from "./view";
import { DataContext, GroupContext } from "../../lib/contexts";
import { ViewMessage } from "../general/viewMessage";
import { Button } from "antd";
import { BLUE } from "../../lib/style_definitions";
import { useNavigate } from "react-router-dom";
import { DishCard } from "../dishes/dishCard";
import {
  callEndpoint,
  doFormalize,
  getStringFromYearWeekDay,
} from "../../lib/functions";
import { ActionName, LF_LAST_FOLDER } from "../../lib/lib";
import { DishPopup } from "../dishes/dishPopup";
import { MInput } from "../elements/mInputs";
import { FolderComponent } from "../dishes/folder";
import { FcOpenedFolder } from "react-icons/fc";
import { MModal } from "../elements/mmodal";
import * as localForage from "localforage";
import { FaChevronLeft } from "react-icons/fa";
import {
  AddDishesToFolderEndpoint,
  CreateFolderEndpoint,
  DeleteFolderEndpoint,
  Dish,
  Folder,
  RemoveDishesFromFolderEndpoint,
  UpdateFolderEndpoint,
  UpdateWeekPlanEndpoint,
  WeekPlan,
} from "@ckal-software/ckal-lib/dist/apps/madplanen";

export function CatalogueView() {
  const [searchValue, setSearchValue] = useState("");
  const [selectedDish, setSelectedDish] = useState<Dish>();
  const [editingFolder, setEditingFolder] = useState<Folder>();
  const [folderName, setFolderName] = useState("");
  const [isUpdatingFolder, setIsUpdatingFolder] = useState(false);
  const [isDeletingFolder, setIsDeletingFolder] = useState(false);
  const [isCreatingFolder, setIsCreatingFolder] = useState(false);
  const [creatingFolder, setCreatingFolder] = useState(false);
  const [newFolderName, setNewFolderName] = useState("");
  const [randomDish, setRandomDish] = useState<Dish>();

  const noScroll = useRef(false);

  const { group, hasFetchedGroup } = useContext(GroupContext);
  const {
    dishes,
    refreshWeekPlan,
    weekPlan,
    folders,
    isFetchingFolders,
    isFetchingDishes,
    refreshFolders,
    setCurrentAction,
    currentAction,
    setWeekPlan,
    setFolders,
    selectedFolder,
    setSelectedFolder,
  } = useContext(DataContext);

  const navigate = useNavigate();

  useEffect(() => {
    setSelectedDish(
      dishes.find(
        (dish) =>
          dish._id ===
            new URLSearchParams(window.location.search).get("selected-dish") ||
          ""
      )
    );
  }, [dishes]);

  useEffect(() => {
    if (currentAction?.action === "SELECT_FOLDER_FOR_DISH") {
      setSelectedFolder(undefined);
    }
  }, [currentAction, setSelectedFolder]);

  useEffect(() => {
    setFolderName(selectedFolder?.folderName || "");
    if (noScroll.current) {
      noScroll.current = false;
      return;
    }
    document.getElementById("folder-dish-container")?.scrollTo(0, 0);
  }, [selectedFolder]);

  const filteredDishes = useMemo(
    () =>
      dishes
        .filter((dish) =>
          doFormalize(dish.name).includes(doFormalize(searchValue))
        )
        .filter((dish) => dish.groupShortId === group?.shortId)
        .filter(
          (dish) =>
            !selectedFolder ||
            selectedFolder.dishIds.includes(dish._id) ||
            selectedFolder.folderName === "Alle"
        )
        .filter(
          (dish) =>
            currentAction?.action !== "SELECT_DISH_FOR_FOLDER" ||
            !currentAction.data.dishIds.includes(dish._id)
        ),
    [dishes, searchValue, group?.shortId, selectedFolder, currentAction]
  );

  function updateRandomDish() {
    const groupDishes = dishes.filter(
      (dish) => dish.groupShortId === group?.shortId
    );

    setRandomDish(groupDishes[Math.floor(Math.random() * groupDishes.length)]);
  }

  return (
    <View>
      {!group ? (
        <ViewMessage
          message="Du er ikke en del af nogen gruppe endnu."
          otherMessage="Gå i indstillinger for at lave eller deltage i en gruppe..."
          loading={!hasFetchedGroup}
        />
      ) : (
        <>
          <div
            style={{
              background: "white",
              padding: "12px 20px",
              width: "calc(100% - 40px)",
              boxShadow:
                "0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)",
              display: "flex",
              alignItems: "center",
              position: "sticky",
              zIndex: 100,
            }}
          >
            <SearchOutlined style={{ marginRight: "8px", fontSize: "20px" }} />
            <MInput
              size="large"
              placeholder={
                selectedFolder ? "Søg opskrifter..." : "Søg mapper..."
              }
              allowClear
              className={"new-item-input"}
              value={searchValue}
              onChange={(e) => setSearchValue(e.target.value)}
            />
            {!currentAction && (
              <div
                style={{
                  fontSize: "16px",
                  color: BLUE,
                  fontWeight: "bold",
                  marginLeft: "24px",
                  width: "40px",
                }}
                onClick={() => navigate("/upsert-dish")}
              >
                {isFetchingFolders || isFetchingDishes ? (
                  <LoadingOutlined
                    style={{ color: BLUE, fontSize: "24px", marginTop: "4px" }}
                  />
                ) : (
                  "NY"
                )}
              </div>
            )}
          </div>
          {filteredDishes.length === 0 &&
            selectedFolder?.folderName === "Alle" && (
              <ViewMessage message="Tryk på NY for at oprette en ret..." />
            )}
          <div
            id="folder-dish-container"
            style={{
              overflow: "auto",
              height: "calc(100% - 62px)",
              width: "calc(100% - 48px)",
              padding: "0 24px",
            }}
          >
            {selectedFolder ? (
              <>
                <div
                  style={{
                    display: "grid",
                    alignItems: "center",
                    margin: "18px 0",
                    height: "40px",
                    gridTemplateColumns:
                      "min-content min-content auto min-content",
                  }}
                >
                  <FaChevronLeft
                    style={{ marginRight: 8, fontSize: 20, color: "#636363" }}
                    onClick={() => {
                      setSelectedFolder(undefined);
                      localForage.setItem(LF_LAST_FOLDER, "");
                    }}
                  />
                  <FcOpenedFolder
                    style={{ marginRight: "8px", fontSize: "28px" }}
                    onClick={() => {
                      setSelectedFolder(undefined);
                      localForage.setItem(LF_LAST_FOLDER, "");
                    }}
                  />
                  <div
                    style={{
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      whiteSpace: "nowrap",
                      marginRight: "8px",
                      color: "#636363",
                    }}
                    onClick={() => {
                      setSelectedFolder(undefined);
                      localForage.setItem(LF_LAST_FOLDER, "");
                    }}
                  >
                    {selectedFolder.folderName}
                  </div>
                  {selectedFolder.folderName !== "Alle" && !currentAction ? (
                    <Button
                      size="large"
                      onClick={() => setEditingFolder(selectedFolder)}
                      icon={<EditOutlined />}
                    >
                      Rediger
                    </Button>
                  ) : (
                    <div />
                  )}
                </div>
                {filteredDishes.length === 0 && (
                  <div style={{ textAlign: "center", padding: "24px 0" }}>
                    {searchValue
                      ? `Ingen retter i mappen '${folderName}' matcher søgningen '${searchValue}'`
                      : "Denne mappe indeholder endnu ikke nogle retter"}
                  </div>
                )}
                <div style={{ display: "grid", rowGap: "32px" }}>
                  {filteredDishes.map((dish) => (
                    <DishCard
                      key={dish._id}
                      dish={dish}
                      onDeleteFromFolder={
                        selectedFolder.folderName === "Alle"
                          ? undefined
                          : async () => {
                              // instant update
                              noScroll.current = true;
                              setSelectedFolder({
                                ...selectedFolder,
                                dishIds: selectedFolder.dishIds.filter(
                                  (did) => did !== dish._id
                                ),
                              });

                              const [err] = await callEndpoint(
                                RemoveDishesFromFolderEndpoint,
                                {
                                  folderId: selectedFolder._id,
                                  dishIds: [dish._id],
                                }
                              );

                              if (err) {
                                alert(
                                  "Sletning af ret fra mappe: Der skete en fejl"
                                );
                                return;
                              }

                              refreshFolders();
                            }
                      }
                      onClick={async () => {
                        if (currentAction?.action === "SELECT_DISH_FOR_DAY") {
                          setCurrentAction(undefined);
                          navigate(
                            `/week-plan?week=${currentAction.data.week}`
                          );

                          const ywdString = getStringFromYearWeekDay(
                            currentAction.data
                          );
                          const newDishes = (
                            weekPlan?.weekPlans[ywdString] || []
                          ).concat([dish._id]);

                          // instant update
                          const newWeekPlan: WeekPlan = JSON.parse(
                            JSON.stringify(weekPlan)
                          );
                          newWeekPlan.weekPlans[ywdString] = newDishes;
                          setWeekPlan(newWeekPlan);

                          const [err] = await callEndpoint(
                            UpdateWeekPlanEndpoint,
                            { yearWeekDay: ywdString, dishIds: newDishes }
                          );

                          if (err) {
                            alert("Opdatering af madplan: Der skete en fejl");
                            return;
                          }

                          refreshWeekPlan();
                        } else if (
                          currentAction?.action === "SELECT_DISH_FOR_FOLDER"
                        ) {
                          setSearchValue("");
                          setIsUpdatingFolder(true);

                          // instant update
                          setFolders(
                            folders.map((f) =>
                              f._id === currentAction.data._id
                                ? { ...f, dishIds: f.dishIds.concat(dish._id) }
                                : f
                            )
                          );
                          setCurrentAction({
                            ...currentAction,
                            data: {
                              ...currentAction.data,
                              dishIds: currentAction.data.dishIds.concat(
                                dish._id
                              ),
                            },
                          });

                          const [err] = await callEndpoint(
                            AddDishesToFolderEndpoint,
                            {
                              folderId: currentAction.data._id,
                              dishIds: [dish._id],
                            }
                          );

                          if (err) {
                            alert("Opdatering af mappe: Der skete en fejl");
                            return;
                          }

                          localForage.setItem(
                            LF_LAST_FOLDER,
                            currentAction.data._id
                          );
                        } else {
                          setSelectedDish(dish);
                        }
                      }}
                    />
                  ))}
                  {selectedFolder.folderName !== "Alle" && !currentAction && (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        marginTop: "36px",
                      }}
                    >
                      <Button
                        size="large"
                        icon={<PlusOutlined />}
                        onClick={() => {
                          setCurrentAction({
                            action: ActionName.SelectDishForFolder,
                            data: selectedFolder,
                          });
                          setSelectedFolder(
                            folders.find((f) => f.folderName === "Alle")
                          );
                        }}
                      >
                        Tilføj retter til mappe
                      </Button>
                    </div>
                  )}
                </div>
              </>
            ) : (
              <>
                {!currentAction && (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "flex-end",
                      marginTop: "16px",
                    }}
                  >
                    <Button
                      icon={<PlusOutlined />}
                      size="large"
                      onClick={() => setCreatingFolder(true)}
                    >
                      Ny mappe
                    </Button>
                  </div>
                )}
                <div
                  style={{
                    display: "grid",
                    gap: "32px",
                    gridTemplateColumns: "1fr 1fr",
                    marginTop: "36px",
                  }}
                >
                  {folders
                    .filter(
                      (f) =>
                        currentAction?.action !== "SELECT_FOLDER_FOR_DISH" ||
                        (f.folderName !== "Alle" &&
                          !f.dishIds.includes(currentAction.data._id))
                    )
                    .filter(
                      (f) =>
                        !searchValue ||
                        (searchValue &&
                          doFormalize(f.folderName).includes(
                            doFormalize(searchValue)
                          ))
                    )
                    .map((folder) => (
                      <FolderComponent
                        key={folder._id}
                        folder={folder}
                        onClick={async () => {
                          if (
                            currentAction?.action === "SELECT_FOLDER_FOR_DISH"
                          ) {
                            // instant-update
                            setFolders(
                              folders.map((f) =>
                                f._id === folder._id
                                  ? {
                                      ...f,
                                      dishIds: f.dishIds.concat(
                                        currentAction.data._id
                                      ),
                                    }
                                  : f
                              )
                            );
                            setSelectedDish(currentAction.data);
                            setSelectedFolder(folder);
                            await localForage.setItem(
                              LF_LAST_FOLDER,
                              folder._id
                            );
                            setCurrentAction(undefined);

                            const [err] = await callEndpoint(
                              AddDishesToFolderEndpoint,
                              {
                                folderId: folder._id,
                                dishIds: [currentAction.data._id],
                              }
                            );

                            if (err) {
                              alert(
                                "Tilføjelse af ret til mappe: Der skete en fejl"
                              );
                              return;
                            }
                          } else {
                            setSelectedFolder(folder);
                            setSearchValue("");
                            localForage.setItem(LF_LAST_FOLDER, folder._id);
                          }
                        }}
                      />
                    ))}
                </div>
                {currentAction?.action !== ActionName.SelectFolderForDish && (
                  <div style={{ marginTop: 48 }}>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        marginBottom: 8,
                      }}
                    >
                      <Button size="large" onClick={() => updateRandomDish()}>
                        Prøv lykken
                      </Button>
                    </div>

                    <DishCard
                      dish={randomDish}
                      onClick={async () => {
                        if (!randomDish) return;

                        if (
                          currentAction?.action === ActionName.SelectDishForDay
                        ) {
                          setCurrentAction(undefined);
                          navigate(
                            `/week-plan?week=${currentAction.data.week}`
                          );

                          const ywdString = getStringFromYearWeekDay(
                            currentAction.data
                          );
                          const newDishes = (
                            weekPlan?.weekPlans[ywdString] || []
                          ).concat([randomDish._id]);

                          // instant update
                          const newWeekPlan: WeekPlan = JSON.parse(
                            JSON.stringify(weekPlan)
                          );
                          newWeekPlan.weekPlans[ywdString] = newDishes;
                          setWeekPlan(newWeekPlan);

                          const [err] = await callEndpoint(
                            UpdateWeekPlanEndpoint,
                            { yearWeekDay: ywdString, dishIds: newDishes }
                          );

                          if (err) {
                            alert("Opdatering af madplan: Der skete en fejl");
                            return;
                          }

                          refreshWeekPlan();
                        } else {
                          setSelectedDish(randomDish);
                        }
                      }}
                    />
                  </div>
                )}
              </>
            )}
            <div style={{ marginBottom: "260px" }} />
          </div>
        </>
      )}
      {selectedDish && (
        <DishPopup
          dish={selectedDish}
          visible={!!selectedDish}
          onClose={() => setSelectedDish(undefined)}
          onAddToWeekPlan={() => {
            setCurrentAction({
              action: ActionName.SelectDayForDish,
              data: selectedDish,
            });
            navigate("/week-plan");
          }}
          folders={folders}
        />
      )}
      <MModal
        visible={creatingFolder}
        onClose={() => setCreatingFolder(false)}
        title="Ny mappe"
        content={
          <div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                marginBottom: "24px",
              }}
            >
              <FcOpenedFolder style={{ fontSize: "120px" }} />
            </div>
            <MInput
              size="large"
              value={newFolderName}
              onChange={(e) => setNewFolderName(e.target.value)}
              placeholder="Mappenavn..."
            />
          </div>
        }
        footer={
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <Button
              size="large"
              onClick={() => {
                setCreatingFolder(false);
                setNewFolderName("");
              }}
            >
              Luk
            </Button>
            <Button
              size="large"
              type="primary"
              loading={isCreatingFolder}
              onClick={async () => {
                const [err] = await callEndpoint(
                  CreateFolderEndpoint,
                  { name: newFolderName },
                  { setWorking: setIsCreatingFolder }
                );

                if (err) {
                  alert("Oprettelse af ny mappe: Der skete en fejl");
                  return;
                }

                setCreatingFolder(false);
                refreshFolders();
                setNewFolderName("");
              }}
            >
              Opret
            </Button>
          </div>
        }
      />
      {editingFolder && (
        <MModal
          visible={!!editingFolder}
          onClose={() => setEditingFolder(undefined)}
          title="Rediger mappe"
          content={
            <div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginBottom: "24px",
                }}
              >
                <FcOpenedFolder style={{ fontSize: "120px" }} />
              </div>
              <MInput
                placeholder="Mappenavn..."
                value={folderName}
                size="large"
                onChange={(e) => setFolderName(e.target.value)}
              />
            </div>
          }
          footer={
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <Button size="large" onClick={() => setEditingFolder(undefined)}>
                Luk
              </Button>
              <div style={{ display: "flex", columnGap: "8px" }}>
                <Button
                  size="large"
                  danger
                  type="primary"
                  loading={isDeletingFolder}
                  onClick={async () => {
                    if (
                      window.confirm(
                        `Er du sikker på at du vil slette mappen '${editingFolder?.folderName}''`
                      )
                    ) {
                      setIsDeletingFolder(true);

                      const [err] = await callEndpoint(
                        DeleteFolderEndpoint,
                        { id: editingFolder._id },
                        { setWorking: setIsDeletingFolder }
                      );

                      if (err) {
                        alert("Sletning af mappe: Der skete en fejl");
                        return;
                      }

                      refreshFolders();
                      setEditingFolder(undefined);
                      setSelectedFolder(undefined);
                    }
                  }}
                >
                  Slet
                </Button>
                <Button
                  size="large"
                  type="primary"
                  loading={isUpdatingFolder}
                  onClick={async () => {
                    const [err] = await callEndpoint(
                      UpdateFolderEndpoint,
                      { id: editingFolder._id, update: { name: folderName } },
                      { setWorking: setIsUpdatingFolder }
                    );

                    if (err) {
                      alert("Opdatering af mappe: Der skete en fejl");
                      return;
                    }

                    refreshFolders();
                    setEditingFolder(undefined);
                  }}
                >
                  Opdater
                </Button>
              </div>
            </div>
          }
        />
      )}
    </View>
  );
}
