import { PLACEHOLDER_URL } from "../../lib/lib";

interface MImgProps {
  url: string | undefined;
  extraClassName?: string;
}

export function MImg(props: MImgProps) {
  return (
    <div
      style={{
        backgroundImage: `url(${props.url}), url(${PLACEHOLDER_URL})`,
      }}
      className={`mimg ${props.extraClassName}`}
    />
  );
}
