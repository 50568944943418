import { useEffect, useState } from "react";

interface AvatarProps {
  email: string;
  firstname: string;
  lastname: string;
  size?: number;
}

export function Avatar(props: AvatarProps) {
  const [avatarImage, setAvatarImage] = useState<File | null>(null);

  useEffect(() => {
    if (!props.email) {
      return;
    }

    let avatarSize = 256;

    if (!props.size) {
      avatarSize = 128;
    } else if (props.size <= 16) {
      avatarSize = 64;
    } else if (props.size <= 32) {
      avatarSize = 128;
    } else if (props.size <= 64) {
      avatarSize = 128;
    } else {
      avatarSize = 256;
    }

    fetch(
      `https://ckal.s3.eu-west-1.amazonaws.com/profile-pictures/${props.email}/avatar-${avatarSize}.png`
    )
      .then((res) => {
        if (!res.ok) {
          return;
        }

        return res.blob();
      })
      .then((blob) => {
        if (!blob) {
          return;
        }

        setAvatarImage(
          new File([blob], "downloaded.jpg", { type: "image/jpeg" })
        );
      });
  }, [props.email, setAvatarImage, props.size]);

  return avatarImage ? (
    <img
      src={URL.createObjectURL(avatarImage)}
      alt=""
      style={{
        width: props.size ?? 128,
        height: props.size ?? 128,
        borderRadius: "50%",
      }}
    />
  ) : (
    <div
      style={{
        height: props.size ?? 128,
        width: props.size ?? 128,
        background: "#51b1ff",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        borderRadius: "50%",
      }}
    >
      <div
        style={{
          fontSize: ((props.size || 128) / 128) * 48,
          fontWeight: "bold",
          color: "white",
        }}
      >
        {(props.firstname[0]?.toUpperCase() || "") +
          (props.lastname[0]?.toUpperCase() || "")}
      </div>
    </div>
  );
}
