import { useContext } from "react";
import { DataContext } from "../../lib/contexts";
import { doFormalize } from "../../lib/functions";
import {
  ItemWithPopularity,
  ShoppingListItem,
  ShoppingListItemIdAndAmount,
} from "../../lib/models";
import { ItemOption } from "./itemOption";

interface AddItemContentProps {
  sortedItemOptions: ItemWithPopularity[];
  currentItemName: string;
  itemsOnList: ShoppingListItemIdAndAmount[];
  itemIsOnList: boolean;
  small?: boolean;
  isIngredients?: boolean;
  onNewItem(): void;
  addItemToList(item: ShoppingListItem): void;
}

export function AddItemContent(props: AddItemContentProps) {
  const { items } = useContext(DataContext);

  return (
    <div
      style={{
        display: "grid",
        gridAutoRows: "min-content",
        height: "100%",
        overflow: "auto",
      }}
    >
      {props.sortedItemOptions.length === 0 && props.itemIsOnList && (
        <div
          style={{
            fontSize: "16px",
            padding: "12px 24px",
            textAlign: "center",
          }}
        >{`'${props.currentItemName}' er allerede på din indkøbsliste...`}</div>
      )}
      <>
        {props.sortedItemOptions.map((item) => (
          <ItemOption
            key={item._id}
            name={item.name}
            small={props.small}
            disabled={!!props.itemsOnList.find((i) => i._id === item._id)}
            isOrganic={item.isOrganic}
            category={item.category}
            popularity={props.isIngredients ? undefined : item.popularity || 0}
            onClick={() => props.addItemToList(item)}
          />
        ))}
      </>
      {props.currentItemName &&
        (!items.find(
          (i) =>
            doFormalize(i.name) === doFormalize(props.currentItemName) &&
            !i.isOrganic
        ) ||
          !items.find(
            (i) =>
              doFormalize(i.name) === doFormalize(props.currentItemName) &&
              i.isOrganic
          )) && (
          <ItemOption
            name={`Opret '${props.currentItemName}' som vare...`}
            category="New"
            small={props.small}
            onClick={props.onNewItem}
          />
        )}
    </div>
  );
}
