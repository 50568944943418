import { getCallInternalEndpointFunction } from "@ckal-software/ckal-lib";
import { CredentialsHandler, CSE_API_KEY, CSE_ID, Weekday } from "./lib";
import { YearWeekDay } from "./models";

export const callEndpoint = getCallInternalEndpointFunction(
  CredentialsHandler.getAccessToken
);

export function intToWeekday(int: number): Weekday {
  if (int === 0) {
    return "Mandag";
  } else if (int === 1) {
    return "Tirsdag";
  } else if (int === 2) {
    return "Onsdag";
  } else if (int === 3) {
    return "Torsdag";
  } else if (int === 4) {
    return "Fredag";
  } else if (int === 5) {
    return "Lørdag";
  } else {
    return "Søndag";
  }
}

async function getFirst10ImageUrls(searchQuery: string) {
  try {
    const response = await fetch(
      `https://www.googleapis.com/customsearch/v1?key=${CSE_API_KEY}&cx=${CSE_ID}&q=${searchQuery}`
    );

    if (!response.ok) {
      throw new Error(`${response.status}: ${response.statusText}`);
    }

    const responseAsJson = await response.json();

    if (responseAsJson.items && responseAsJson.items.length > 0) {
      return responseAsJson.items
        .map((item: { pagemap?: { cse_image: { src: string }[] } }) => {
          if (item.pagemap && item.pagemap["cse_image"]) {
            return item.pagemap["cse_image"][0]?.src;
          } else {
            return "";
          }
        })
        .filter((url: string) => !!url);
    }
  } catch (error) {
    console.log(error);
    return [];
  }
}

export async function getDishImages(dishName: string) {
  const fetches = [
    getFirst10ImageUrls(dishName + " opskrift"),
    getFirst10ImageUrls(dishName + " mad"),
    getFirst10ImageUrls(dishName + " lækkert"),
  ];
  const promises = await Promise.all(fetches);
  return Array.from(
    new Set(promises.reduce((prev, curr) => prev.concat(curr), [])).values()
  ) as string[];
}

export function capitalizeFirstLetter(s: string) {
  return s.charAt(0).toUpperCase() + s.slice(1);
}

export function getYearWeekDayRelative(relativeWeek: number): YearWeekDay {
  const d = new Date();
  d.setUTCDate(d.getUTCDate() + 7 * relativeWeek);
  return getYearWeekDay(d);
}

function getYearWeekDay(date: Date): YearWeekDay {
  date = new Date(
    Date.UTC(date.getFullYear(), date.getMonth(), date.getDate())
  );
  const day = date.getDay();
  const timestamp = date.getTime();
  date.setUTCDate(date.getUTCDate() + 4 - (date.getUTCDay() || 7));
  const yearStart = new Date(Date.UTC(date.getUTCFullYear(), 0, 1));
  const weekNo = Math.ceil(
    ((date.getTime() - yearStart.getTime()) / 86400000 + 1) / 7
  );

  return {
    year: date.getUTCFullYear(),
    week: day === 0 ? weekNo - 1 : weekNo,
    day: day === 0 ? 6 : day - 1,
    timestamp: timestamp,
  };
}

export function getDateFromYearWeekDay(yearWeekDay: YearWeekDay, day: number) {
  const newDate = new Date(yearWeekDay.timestamp);
  newDate.setUTCDate(newDate.getUTCDate() + (day - yearWeekDay.day));

  const monthAsText = [
    "januar",
    "februar",
    "marts",
    "april",
    "maj",
    "juni",
    "juli",
    "august",
    "september",
    "oktober",
    "november",
    "december",
  ][newDate.getMonth()];

  return newDate.getDate() + ". " + monthAsText;
}

export function doFormalize(s: string) {
  return s
    .toLowerCase()
    .normalize("NFD")
    .replace(/[\u0300-\u036f]/g, "");
}

export function getStringFromYearWeekDay(
  yearWeekDay: YearWeekDay,
  replaceDay?: number
) {
  return (
    "" +
    yearWeekDay.year +
    yearWeekDay.week.toString().padStart(2, "0") +
    (replaceDay !== undefined ? replaceDay : yearWeekDay.day)
  );
}

export async function forceRefresh() {
  window.location.reload();
}
