import { getYearWeekDayRelative } from "../../lib/functions";
import { BLUE } from "../../lib/style_definitions";

interface WeekNumberBoxProps {
  weekNumber: number;
  selectedWeekNumber: number;
  onClick(): void;
}

export function WeekNumberBox(props: WeekNumberBoxProps) {
  const big = getYearWeekDayRelative(0).week === props.weekNumber;
  const selected = props.weekNumber === props.selectedWeekNumber;

  return (
    <div
      style={{
        padding: big ? "3px 4px 5px 3px" : "3px 4px 4px 4px",
        background: "#f5f5f5",
        borderRadius: "4px",
        fontSize: big ? "22px" : "18px",
        margin: "0 8px",
        width: big ? "42px" : "36px",
        height: big ? "42px" : "36px",
        textAlign: "center",
        color: selected ? BLUE : "#9e9e9e",
        transition: "all 0.3s",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        position: "relative",
      }}
      onClick={props.onClick}
    >
      <div style={{ marginTop: big ? "1px" : "2px" }}>{props.weekNumber}</div>
      {selected && (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            position: "absolute",
            bottom: big ? 8 : 6,
            width: "100%",
          }}
        >
          <div
            style={{
              height: big ? 3 : 2,
              background: BLUE,
              transition: "all 0.3s",
              borderRadius: "12px",
              width: "50%",
            }}
          />
        </div>
      )}
    </div>
  );
}
