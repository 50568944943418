import { FaAppleAlt, FaCandyCane, FaBaby } from "react-icons/fa";
import {
  GiBroom,
  GiCoffeeBeans,
  GiHamShank,
  GiMedicines,
  GiMilkCarton,
  GiPerfumeBottle,
  GiSlicedBread,
  GiWineBottle,
} from "react-icons/gi";
import { IoIosShirt, IoIosSnow } from "react-icons/io";
import { RiHome6Line, RiPlantLine, RiTempColdLine } from "react-icons/ri";
import { MdDevices } from "react-icons/md";
import { VscSymbolMisc } from "react-icons/vsc";
import { CheckOutlined } from "@ant-design/icons";
import {
  categoryIconStyles,
  categoryToColor,
  ItemCategoryWithBought,
} from "./lib";
import { Folder } from "@ckal-software/ckal-lib/dist/apps/madplanen";

export const FolderNamedAll: Folder = {
  _id: "__something__",
  groupShortId: 0,
  folderName: "Alle",
  dishIds: [] as string[],
};

export const categoryToIcon: {
  [index in ItemCategoryWithBought]: React.ReactNode;
} = {
  Andet: (
    <VscSymbolMisc
      style={categoryIconStyles}
      color={categoryToColor["Andet"]}
    />
  ),
  Baby: <FaBaby style={categoryIconStyles} color={categoryToColor["Baby"]} />,
  Bolig: (
    <RiHome6Line style={categoryIconStyles} color={categoryToColor["Bolig"]} />
  ),
  "Brød & Bager": (
    <GiSlicedBread
      style={categoryIconStyles}
      color={categoryToColor["Brød & Bager"]}
    />
  ),
  Drikkevarer: (
    <GiWineBottle
      style={categoryIconStyles}
      color={categoryToColor["Drikkevarer"]}
    />
  ),
  Elektronik: (
    <MdDevices
      style={categoryIconStyles}
      color={categoryToColor["Elektronik"]}
    />
  ),
  Frost: (
    <IoIosSnow style={categoryIconStyles} color={categoryToColor["Frost"]} />
  ),
  "Frugt & Grønt": (
    <FaAppleAlt
      style={categoryIconStyles}
      color={categoryToColor["Frugt & Grønt"]}
    />
  ),
  "Hus & Have": (
    <RiPlantLine
      style={categoryIconStyles}
      color={categoryToColor["Hus & Have"]}
    />
  ),
  Kolonial: (
    <GiCoffeeBeans
      style={categoryIconStyles}
      color={categoryToColor["Kolonial"]}
    />
  ),
  "Kød & Fisk": (
    <GiHamShank
      style={categoryIconStyles}
      color={categoryToColor["Kød & Fisk"]}
    />
  ),
  Medicin: (
    <GiMedicines
      style={categoryIconStyles}
      color={categoryToColor["Medicin"]}
    />
  ),
  Mejeri: (
    <GiMilkCarton
      style={categoryIconStyles}
      color={categoryToColor["Mejeri"]}
    />
  ),
  "På køl": (
    <RiTempColdLine
      style={categoryIconStyles}
      color={categoryToColor["På køl"]}
    />
  ),
  "Personlig pleje": (
    <GiPerfumeBottle
      style={categoryIconStyles}
      color={categoryToColor["Personlig pleje"]}
    />
  ),
  Rengøring: (
    <GiBroom style={categoryIconStyles} color={categoryToColor["Rengøring"]} />
  ),
  Snacks: (
    <FaCandyCane style={categoryIconStyles} color={categoryToColor["Snacks"]} />
  ),
  Tekstil: (
    <IoIosShirt style={categoryIconStyles} color={categoryToColor["Tekstil"]} />
  ),
  Købte: (
    <CheckOutlined
      style={categoryIconStyles}
      color={categoryToColor["Købte"]}
    />
  ),
};
