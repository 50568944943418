import { ApplicationName, refreshIfOld } from "@ckal-software/ckal-lib";
import { useEffect } from "react";
import { version } from "../../package.json";

export function useRefreshIfOld() {
  useEffect(() => {
    async function checkOnVisibilityChange() {
      if (document.visibilityState === "visible") {
        await refreshIfOld(ApplicationName.Madplanen, version);
      }
    }

    document.addEventListener("visibilitychange", checkOnVisibilityChange);

    return () => {
      document.removeEventListener("visibilitychange", checkOnVisibilityChange);
    };
  }, []);
}
