import { YearWeekDay } from "../../lib/models";
import { getYearWeekDayRelative } from "../../lib/functions";
import { WeekNumberBox } from "./weekNumberBox";

interface WeekNumberSelectorProps {
  yearWeekDay: YearWeekDay;
  setYearWeekDay(yearWeekDay: YearWeekDay): void;
}

export function WeekNumberSelector(props: WeekNumberSelectorProps) {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        width: "100%",
        alignItems: "center",
      }}
    >
      <WeekNumberBox
        weekNumber={getYearWeekDayRelative(-2).week}
        onClick={() => props.setYearWeekDay(getYearWeekDayRelative(-2))}
        selectedWeekNumber={props.yearWeekDay.week}
      />
      <WeekNumberBox
        weekNumber={getYearWeekDayRelative(-1).week}
        onClick={() => props.setYearWeekDay(getYearWeekDayRelative(-1))}
        selectedWeekNumber={props.yearWeekDay.week}
      />
      <WeekNumberBox
        weekNumber={getYearWeekDayRelative(0).week}
        onClick={() => props.setYearWeekDay(getYearWeekDayRelative(0))}
        selectedWeekNumber={props.yearWeekDay.week}
      />
      <WeekNumberBox
        weekNumber={getYearWeekDayRelative(1).week}
        onClick={() => props.setYearWeekDay(getYearWeekDayRelative(1))}
        selectedWeekNumber={props.yearWeekDay.week}
      />
      <WeekNumberBox
        weekNumber={getYearWeekDayRelative(2).week}
        onClick={() => props.setYearWeekDay(getYearWeekDayRelative(2))}
        selectedWeekNumber={props.yearWeekDay.week}
      />
    </div>
  );
}
